import * as React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import { Helmet } from 'react-helmet';
import { PageProps } from 'gatsby';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';

import ActionButton from '../components/ActionButton';

import NewsletterDialog from '../components/dialogs/Newsletter';

const generateStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: 'none'
    }
  },
  SectionContainer: {
    marginTop: '160px',
    marginBottom: '160px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  MainGrid: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  KeyTerm: {
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap'
    }
    // textDecoration: 'underline',
    // textDecorationThickness: '2px'
  }
}));

const IndexPage = (props: PageProps): React.ReactNode => {
  const myClasses = generateStyles();
  const muitheme = useTheme();
  const VIEW_D_SM = useMediaQuery(muitheme.breakpoints.down('sm'));
  return (
    <main style={{ textAlign: 'center' }}>
      <Helmet>
        <title>Micro SaaS Capital</title>
      </Helmet>
      <Grid container className={myClasses.SectionContainer}>
        <Grid item md={10} xs={12}>
          <Typography
            id="whatwedo"
            component="h1"
            variant="h3"
            style={{ fontWeight: 300, lineHeight: 1.2 }}
            // gutterBottom
          >
            We <span style={{ fontWeight: 500 }}>acquire</span>, <span style={{ fontWeight: 500 }}>build</span>, and <span style={{ fontWeight: 500 }}>grow</span> amazing software businesses.
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: '60px' }}
            // gutterBottom
          >
            We give founders wonderful exits with <span className={myClasses.KeyTerm}>simple and fair terms</span>, a <span className={myClasses.KeyTerm}>fast and stress-free process</span>, and an <span className={myClasses.KeyTerm}>awesome new home</span> where your business will continue to thrive.
            {/* with our passionate team of fellow founders dedicated to growing it sustainably over the <span style={{ whiteSpace: 'nowrap' }}>long-term</span>. */}
          </Typography>
          {/* <Typography
            style={{ marginTop: '40px' }}
            // gutterBottom
          >
            Our passionate team of fellow founders is dedicated to growing our family of businesses sustainably over the <span style={{ whiteSpace: 'nowrap' }}>long-term</span> with permanent capital.
          </Typography> */}
        </Grid>
      </Grid>
      {/*<Grid container className={myClasses.SectionContainer}>
        <Grid item md={10} xs={12}>
          <Typography
            component="h2"
            variant="h4"
            style={{ fontWeight: 300 }}
            gutterBottom
          >
            Small Businesses Are Different
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <Typography gutterBottom>
            TODO talk about the chasm between solo-profitable and team-profitable.
          </Typography>
          <Typography gutterBottom>
            Our typical acquisition is a small software-as-a-service (SaaS) business with as little as 50k ARR up to a few million in ARR.
            We love underappreciated high-quality products that don't fit the mould of traditional venture capital.
          </Typography>
        </Grid>
        </Grid>*/}
      <Grid container className={myClasses.SectionContainer}>
        <Typography
          component="h2"
          variant="h4"
          style={{ fontWeight: 300 }}
          gutterBottom
        >
          What We Like
        </Typography>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ marginTop: '8px' }}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key="Valuable Products"
          >
            <Typography component="h3" variant="h6" style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 500 }}>Valuable</span> Products
            </Typography>
            <Typography gutterBottom>
              Like mission-critical tools or anything customers really value and pay for directly.
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key="Happy Customers"
          >
            <Typography component="h3" variant="h6" style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 500 }}>Happy</span> Customers
            </Typography>
            <Typography gutterBottom>
              Our single best measure of product quality and the raison d'être of any business.
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key="Sustainable Growth"
          >
            <Typography component="h3" variant="h6" style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 500 }}>Sustainable</span> Growth
            </Typography>
            <Typography gutterBottom>
              Driven by a strong brand or community or other genuine organic channels.
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key="Recurring Revenues"
          >
            <Typography component="h3" variant="h6" style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 500 }}>Recurring</span> Revenues
            </Typography>
            <Typography gutterBottom>
              Like SaaS or similar subscription models built on long-term customer relationships.
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key="High Margins"
          >
            <Typography component="h3" variant="h6" style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 500 }}>High</span> Margins
            </Typography>
            <Typography gutterBottom>
              Like software or similar services with high automation, efficiency, and productivity.
            </Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={12}
            key="Typical"
          >
            <Typography gutterBottom>
              We typically focus on small, underappreciated SaaS businesses that don't fit the criteria of traditional venture capital or private equity.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={myClasses.SectionContainer}>
        <Typography
          component="h2"
          variant="h4"
          style={{ fontWeight: 300 }}
          gutterBottom
        >
          Our Simple Process
        </Typography>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="flex-start"
          alignContent="center"
          style={{ marginTop: '8px', marginBottom: '8px' }}
        >
          <Grid
            item
            style={{ marginLeft: '23px', marginRight: '23px' }}
            key="Response"
          >
            <Typography component="h3" variant="h5" style={{ fontWeight: 300 }}>
              Response
            </Typography>
            <Typography>
              in <span style={{ fontWeight: 600 }}>24-48 hours</span>
            </Typography>
          </Grid>
          <Grid
            item
            style={{ marginLeft: '23px', marginRight: '23px' }}
            key="Offer"
          >
            <Typography component="h3" variant="h5" style={{ fontWeight: 300 }}>
              Offer
            </Typography>
            <Typography>
              in <span style={{ fontWeight: 600 }}>3-5 days</span>
            </Typography>
          </Grid>
          <Grid
            item
            style={{ marginLeft: '23px', marginRight: '23px' }}
            key="Closing"
          >
            <Typography component="h3" variant="h5" style={{ fontWeight: 300 }}>
              Closing
            </Typography>
            <Typography>
              in <span style={{ fontWeight: 600 }}>15-30 days</span>
            </Typography>
          </Grid>
        </Grid>
        <Typography gutterBottom>
          <CheckIcon fontSize="small" style={{ marginBottom: '-4px' }} /> Efficient due-diligence process that respects founders' time.
        </Typography>
        <Typography gutterBottom>
          <CheckIcon fontSize="small" style={{ marginBottom: '-4px' }} /> Cash-upfront offers without complex terms.
        </Typography>
        <Typography gutterBottom>
          <CheckIcon fontSize="small" style={{ marginBottom: '-4px' }} /> No in-person meetings.
        </Typography>
      </Grid>
      <Grid container className={myClasses.SectionContainer}>
        <Typography
          id="ourpromise"
          component="h2"
          variant="h4"
          style={{ fontWeight: 300 }}
          gutterBottom
        >
          Our Promise
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          style={{ fontWeight: 300 }}
          gutterBottom
        >
          The <span style={{ fontWeight: 500 }}>best</span> founder experience, period.
        </Typography>
        <Grid item xs={8}>
          <Typography gutterBottom>
            We are prompt and focused - we never waste founders' time.
          </Typography>
          <Typography gutterBottom>
              We are honest, upfront, and transparent with our offers - we mean what we say and are committed to following through.
          </Typography>
          <Typography gutterBottom>
              We build for the long-term with permanent capital - our holding period is forever (we do not "flip").
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={myClasses.SectionContainer} style={{ marginTop: '100px', marginBottom: '100px' }}>
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
        >
          Not sure if your business meets our criteria?
        </Typography>
        <Typography style={{ maxWidth: '800px' }} gutterBottom>
          Get in touch anyway - we'll let you know quickly, we're always happy to meet fellow founders, and we may connect you with partners we know who can help!
        </Typography>
        <ActionButton variant="contained" color="primary" to="mailto:hello@microsaascapital.com" style={{ marginTop: '12px' }}>Get in touch</ActionButton>
      </Grid>
      <NewsletterDialog open={props.location.hash === '#newsletter'} /> {/* TODO move this to the bottom of footer */}
    </main>
  );
};

export default IndexPage;

/*
        <Container className={myClasses.SectionContainer}>
          <Typography
            component="h2"
            variant="h4"
            style={{ fontWeight: 300 }}
            gutterBottom
          >
            Why us?
          </Typography>
          <Grid
            container
            spacing={9}
            direction="row"
            justify="center"
            alignItems="flex-start"
            className={myClasses.MainGrid}
          >
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems={VIEW_D_SM ? 'center' : 'flex-start'}
              lg={3}
              md={4}
              xs={12}
              key="Venture Capital"
            >
              <Typography gutterBottom>
                Venture Capital (growth-at-all-costs)
                Long process (a few months) and complex term sheets
                No cash for founders - long-term commitment
                Pressure to 10x each round and return capital in 5-10 years
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems={VIEW_D_SM ? 'center' : 'flex-start'}
              lg={3}
              md={4}
              xs={12}
              key="Private Equity"
            >
              <Typography gutterBottom>
                Private Equity (cash-harvesting)
                Very long process (many months)
                Very complex term sheets with earn-outs etc.
                Flipping business every 3-5 years
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems={VIEW_D_SM ? 'center' : 'flex-start'}
              lg={3}
              md={4}
              xs={12}
              key="Us"
            >
              <Typography gutterBottom>
                MicroSaaS Capital
                Simple, fast, efficient process
                Cash upfront, no earnouts, no gimmics/nonsense
                Run by fellow founders, builders, indie hackers
                Long-term, permanent capital (no LPs, exit timelines, etc.) - a safe/wonderful home for your project
              </Typography>
            </Grid>
          </Grid>
        </Container>
*/