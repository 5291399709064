import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const StyleGenerators = {
  Button: makeStyles((theme) => ({

  }))
};

type Props = {
  open: boolean
}

function NewsletterDialog(props: Props): JSX.Element {
  const Styles = {

  };
  function closeMyself() {
    navigate('/');
  }
  return (
    <Dialog
      open={props.open}
      onClose={closeMyself}
    >
      <DialogTitle>Newsletter</DialogTitle>
      <DialogContent>
        TODO
      </DialogContent>
    </Dialog>
  );
}

export default NewsletterDialog;